// Modal.js
import React from "react";
import "../styles/Modal.css"; // Assuming you will create styles for the modal

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};

export default Modal;
