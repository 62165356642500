import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfService from './pages/TermsOfService';
import ComingSoon from './pages/ComingSoon';
import ContactUs from './pages/ContactUs';
import PageNotFound from './pages/404Page';
import HelpCenterPage from './pages/HelpCenter';
import WhyChooseUs from './pages/WhyChooseUs';
import EmployeeEngagement from './pages/EmployeeEngagement';
import PredictiveAnalysisPage from './pages/PredictiveAnalysis';
import IndividualPricingPage from './pages/IndividualPricingPage';
import EnterprisePricingPage from './pages/EnterprisePricingPage';
import ContentLibrary from './pages/ContentLibrary';
import EventPage from './pages/EventPage';
import InclusivityCommunication from './pages/InclusivityCommunication';
import ManagerInsights from './pages/ManagerInsights';
import CounselingSupport from './pages/CounselingSupport';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/individual-pricing" element={<IndividualPricingPage />} />
        <Route path="/enterprise-pricing" element={<EnterprisePricingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/schedule-demo" element={<ComingSoon />} />
        <Route path="/take-a-tour" element={<ComingSoon />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/help-center" element={<HelpCenterPage />} />
        <Route path="/why-us" element={<WhyChooseUs />} />
        <Route path="/employee-engagement" element={<EmployeeEngagement />} />
        <Route path="/predictive-analysis" element={<PredictiveAnalysisPage />} />
        <Route path="/inclusivity-communication" element={<InclusivityCommunication />} />
        <Route path="/manager-insights" element={<ManagerInsights />} />
        <Route path="/counselling-support" element={<CounselingSupport />} />
        <Route path="/content-library" element={<ContentLibrary />} />
        <Route path="/events" element={<EventPage />} />
        <Route path="*" element={<PageNotFound />} /> {/* This handles all unknown routes */}
      </Routes>
    </Router>
  );
}

export default App;