import React, { useState } from "react";
import "../styles/EventPage.css";
import Layout from "./Layout";

const EventPage = () => {
  // State to manage events
  const [events, setEvents] = useState([]);

  return (
    <Layout>
      <div className="event-page">
        {/* Header Section */}
        <header className="header">
          <h1>Upcoming Events</h1>
          <p>
            Stay updated with the latest events and workshops focused on
            workplace culture, inclusivity, and engagement.
          </p>
        </header>

        {/* Event Listing Section */}
        <div className="events-section">
          {events.length > 0 ? (
            events.map((event, index) => (
              <div className="event-card" key={index}>
                <h3>{event.title}</h3>
                <p>{event.description}</p>
                <p>
                  <strong>Date:</strong> {event.date}
                </p>
              </div>
            ))
          ) : (
            <div className="no-events">
              <p>No events available now.</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EventPage;
