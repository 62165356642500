import React from "react";
import "../styles/TestimonialsSection.css"; // Create this CSS file for styling

const testimonials = [
  {
    id: 1,
    name: "Ajit Kumar (Piramal Healthcare)",
    feedback: `As an employee at Piramal Healthcare, CultureLinkr has been instrumental in reducing stress and improving personal well-being. The platform's wellness programs and mental health resources have helped me stay focused and maintain a healthier work-life balance.`,
  },
  {
    id: 2,
    name: "Nandkishore (VP Agritech)",
    feedback:
      "CultureLinkr has greatly improved my mental health and personal wellbeing. Its tools promote balance and mindfulness, reducing stress and boosting focus, while fostering a more inclusive and positive work environment at VP Agritech.",
  },
  {
    id: 3,
    name: "Tejaswini Kumar (Velnik)",
    feedback:
      "As a legal trainee at Velnik, CultureLinkr has significantly improved my mental well-being. The Wellbeing Tools and virtual team activities help me manage stress, while the real-time feedback keeps me engaged. The platform fosters a positive, inclusive environment, making work more fulfilling and balanced.",
  },
];

const TestimonialsSection = () => {
  return (
    <div className="testimonials-container">
      <h2>
        Discover how CultureLinkr has transformed workplaces through our
        customers' success stories!
      </h2>
      <div className="testimonials-grid">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <p>"{testimonial.feedback}"</p>
            <h4>- {testimonial.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialsSection;
