import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/ContactUs.css"; // Ensure you have this CSS file
import Layout from "./Layout";

const ContactUs = () => {
  return (
    <Layout>
      <div className="contact-us-container">
        <h1 className="contact-title">Get in Touch with Us!</h1>
        <p className="contact-description">
          We’re here to help! Reach out to us through any of the contact methods
          below.
        </p>

        <div className="contact-info">
          <div className="info-item">
            <h2>Email Us</h2>
            <p>
              <a
                href="mailto:support@culturelinkr.com"
                className="contact-link"
              >
                support@culturelinkr.com
              </a>
            </p>
          </div>

          <div className="info-item">
            <h2>Call Us</h2>
            <p>
              <a href="tel:+918839307937" className="contact-link">
                +91 88393-07937
              </a>
            </p>
          </div>

          <div className="info-item">
            <h2>Follow Us on Social Media</h2>
            <div className="social-links">
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/company/culturelinkr/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </div>

          <div className="info-item">
            <h2>Visit Us</h2>
            <p>284 Sanjay Nagar, Bijalpur</p>
            <p>Indore, Madhya Pradesh, India 452012</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
