import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/404Page.css"; // Custom styles for the 404 page
import Layout from './Layout';

const PageNotFound = () => {
  return (
    <Layout>
      <div className="not-found-container">
        <div className="not-found-content">
          <h1 className="error-title">404</h1>
          <h2 className="error-subtitle">Oops! Page not found</h2>
          <p className="error-message">
            Sorry, the page you are looking for does not exist. It might have been moved or deleted.
          </p>
          <Link to="/" className="home-button">
            Back to Home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
