import React from "react";
import "../styles/ComingSoon.css"; // Import your CSS for styling
import Layout from "./Layout";

const ComingSoon = () => {
  const email = "support@culturelinkr.com"; // Replace with your email
  const whatsappNumber = "+918839307937"; // Replace with your WhatsApp number

  return (
    <Layout>
      <div className="coming-soon-main-container">
        <div className="coming-soon-container">
          <h1 className="coming-soon-title">Coming Soon!</h1>
          <p className="coming-soon-message">
            We're working hard to bring you something amazing. Stay tuned!
          </p>
          <div className="contact-info">
            <p>If you need more information or want to connect:</p>
            <a href={`mailto:${email}`} className="contact-link">
              Email Us
            </a>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              className="contact-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              WhatsApp Us
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ComingSoon;
