import React from "react";
import "../styles/CounselingSupport.css"; // Create a separate CSS file for styling
import Layout from "./Layout";

const CounselingSupport = () => {
  return (
    <Layout>
      <div className="counseling-support">
        {/* Header Section */}
        <header className="header-section">
          <h1>Counselling & Support</h1>
          <p>
            Providing personalized counselling and support resources to help
            employees and managers thrive in the workplace.
          </p>
        </header>

        {/* Overview Section */}
        <section className="overview-section">
          <h2>What is Counselling & Support?</h2>
          <p>
            CultureLinkr’s Counselling & Support services provide one-on-one
            access to professional counselling, coaching resources, and
            emotional well-being tools to promote mental health and workplace
            harmony. These resources ensure employees feel supported, heard, and
            empowered in their work environment.
          </p>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>Key Features of Our Counselling & Support Tools</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>One-on-One Counselling</h3>
              <p>
                Offer confidential counselling sessions with mental health
                professionals to address personal and work-related challenges.
              </p>
            </div>
            <div className="feature-item">
              <h3>Manager Coaching Resources</h3>
              <p>
                Equip managers with coaching tools to provide emotional support
                and guidance to their teams.
              </p>
            </div>
            <div className="feature-item">
              <h3>Emotional Wellbeing Check-Ins</h3>
              <p>
                Implement regular check-ins to assess employees' emotional
                well-being, providing early intervention if needed.
              </p>
            </div>
            <div className="feature-item">
              <h3>Access to Support Resources</h3>
              <p>
                Provide employees with access to a library of mental health,
                stress management, and work-life balance resources.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section">
          <h2>Benefits of Counselling & Support</h2>
          <div className="benefits-list">
            <div className="benefit-card">
              <h3>Improved Mental Health</h3>
              <p>
                Offering access to professional counselling helps employees
                manage stress and promotes overall mental well-being.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Higher Employee Satisfaction</h3>
              <p>
                Employees who feel supported are more likely to stay with the
                organization and contribute positively.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Enhanced Managerial Support</h3>
              <p>
                Coaching resources equip managers to better support their teams,
                fostering trust and open communication.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Reduced Burnout</h3>
              <p>
                Regular emotional check-ins and access to mental health tools
                reduce burnout, helping employees maintain balance.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h2>Need Support for Your Team?</h2>
          <p>
            Join CultureLinkr and provide your team with the Counselling and
            emotional well-being resources they need to thrive in the workplace.
          </p>
          <button className="cta-button">
            <a href="take-a-tour">Take a Tour</a>
          </button>
        </section>
      </div>
    </Layout>
  );
};

export default CounselingSupport;
