import React, { useState } from "react";
import "../styles/IndividualPricingPage.css";
import Modal from "../components/Modal"; // Assuming you have a Modal component
import Layout from "./Layout";

const IndividualPricingPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [faqs] = useState([
    {
      question: "What is included in the Standard Plan?",
      answer:
        "The Standard Plan includes access to essential wellness programs, basic inclusivity assessments, standard feedback collection tools, and basic reports and analytics.",
    },
    {
      question: "What are the benefits of the Premium Plan?",
      answer:
        "The Premium Plan offers access to all wellness programs, comprehensive inclusivity insights, advanced feedback tools, AI-driven personalized recommendations, and more.",
    },
    {
      question: "Can I switch plans later?",
      answer:
        "Yes, you can switch from the Standard Plan to the Premium Plan or vice versa at any time. Please contact support for assistance.",
    },
    {
      question: "How can I cancel my subscription?",
      answer:
        "You can cancel your subscription at any time through your account settings or by contacting our support team.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept various payment methods, including credit cards, debit cards, and PayPal.",
    },
  ]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <div className="pricing-page">
        <h1 className="page-title">Choose Your Pricing Plan</h1>
        <h3 className="page-sub-title">Your journey to enhanced wellbeing and inclusivity starts here!</h3>

        <div className="pricing-plans">
          {/* Standard Plan */}
          <div className="pricing-card standard">
            <h2>Standard Plan</h2>
            <p className="original-price">
              Monthly: <span className="discounted-price">$129</span>{" "}
              <span className="price-cut">$149</span>
            </p>
            <ul className="benefits-list">
              <li>Access to Essential Wellness Programs</li>
              <li>Basic Inclusivity Assessments</li>
              <li>Standard Feedback Collection Tools</li>
              <li>Basic Reports and Analytics</li>
            </ul>
            <button className="cta-button">
              <a href="/schedule-demo">Choose Standard</a>
            </button>
            <span style={{ color: "red" }}>
              Please contact us over email or whatsapp to pre-book your place
              for amazing benefits.
            </span>
          </div>

          {/* Premium Plan */}
          <div className="pricing-card premium">
            <h2>Premium Plan</h2>
            <p className="original-price">
              Monthly: <span className="discounted-price">$249</span>{" "}
              <span className="price-cut">$299</span>
            </p>
            <ul className="benefits-list">
              <li>All Wellness Programs, including Premium Options</li>
              <li>Comprehensive Inclusivity Insights</li>
              <li>Advanced Feedback Tools with Trend Analysis</li>
              <li>AI-driven Personalized Recommendations</li>
            </ul>
            <button className="cta-button">
              <a href="/schedule-demo">Choose Premium</a>
            </button>
            <span style={{ color: "red" }}>
              Please contact us over email or whatsapp to pre-book your place
              for amazing benefits.
            </span>
          </div>
        </div>

        <button className="comparison-button" onClick={handleModalOpen}>
          Compare Plans
        </button>

        {/* Modal for Price Comparison */}
        {isModalOpen && (
          <Modal onClose={handleModalClose}>
            <h2>Price Comparison</h2>
            <table>
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Standard Plan</th>
                  <th>Premium Plan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Access to Wellness Programs</td>
                  <td>Essential wellness programs</td>
                  <td>All wellness programs, including premium options</td>
                </tr>
                <tr>
                  <td>Inclusivity and Diversity Insights</td>
                  <td>Basic assessments and reports</td>
                  <td>Comprehensive insights with advanced metrics</td>
                </tr>
                <tr>
                  <td>Feedback System</td>
                  <td>Standard collection</td>
                  <td>Advanced tools with trend analysis</td>
                </tr>
                <tr>
                  <td>Reports and Analytics</td>
                  <td>Basic reports</td>
                  <td>Advanced reports with custom analytics</td>
                </tr>
                <tr>
                  <td>Personalized Recommendations</td>
                  <td>Limited recommendations</td>
                  <td>AI-driven recommendations</td>
                </tr>
                <tr>
                  <td>Gamification and Rewards</td>
                  <td>Basic elements</td>
                  <td>Enhanced with badges and leaderboards</td>
                </tr>
                <tr>
                  <td>User Experience Customization</td>
                  <td>Standard experience</td>
                  <td>Customizable dashboards</td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>Standard email support</td>
                  <td>Priority support</td>
                </tr>
                <tr>
                  <td>Mobile Access</td>
                  <td>Basic access</td>
                  <td>Full-feature app access</td>
                </tr>
                <tr>
                  <td>Data Export Options</td>
                  <td>Standard formats</td>
                  <td>Advanced options with detailed history</td>
                </tr>
                <tr>
                  <td>1-on-1 Wellness Session</td>
                  <td>$15/session (charged)</td>
                  <td>2 per month included, $15/session afterwards</td>
                </tr>
              </tbody>
            </table>
            <button className="close-modal" onClick={handleModalClose}>
              Close
            </button>
          </Modal>
        )}

        {/* FAQs Section */}
        <div className="faqs-section">
          <h2>Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <h3 className="faq-question">{faq.question}</h3>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default IndividualPricingPage;
