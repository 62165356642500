import React from 'react';
import Layout from './Layout'; // Import the Layout component
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import TestimonialsSection from '../components/TestimonialsSection';
import CallToAction from '../components/CallToAction';
import WhyCultureLinkr from '../components/WhyCultureLinkr';

const HomePage = () => {
  return (
    <Layout>
      <HeroSection />
      <FeaturesSection />
      <TestimonialsSection />
      <WhyCultureLinkr />
      <CallToAction />
    </Layout>
  );
};

export default HomePage;
