import React from "react";
import "../styles/InclusivityCommunication.css"; // Create a separate CSS file for styling
import Layout from "./Layout";

const InclusivityCommunication = () => {
  return (
    <Layout>
      <div className="inclusivity-communication">
        {/* Header Section */}
        <header className="header-section">
          <h1>Inclusivity and Communication</h1>
          <p>
            Building a workplace where communication is bias-free, and
            inclusivity is at the core of every interaction.
          </p>
        </header>

        {/* Overview Section */}
        <section className="overview-section">
          <h2>What is Inclusivity and Communication?</h2>
          <p>
            Inclusivity in the workplace means creating an environment where
            every individual feels valued and heard, regardless of their
            background. Effective communication ensures that all employees can
            contribute equally and that their voices are acknowledged. At
            CultureLinkr, we offer tools to promote inclusivity, ensure
            bias-free communication, and foster a healthy, diverse workspace.
          </p>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>Key Features of Our Inclusivity Tools</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>Bias-Free Communication Analyzer</h3>
              <p>
                Ensure communication is respectful and inclusive with tools that
                identify potential unconscious biases in messages and
                interactions.
              </p>
            </div>
            <div className="feature-item">
              <h3>Inclusivity Training Modules</h3>
              <p>
                Provide employees with interactive modules to educate them on
                inclusivity best practices and promote a bias-free workplace.
              </p>
            </div>
            <div className="feature-item">
              <h3>Cultural Competency Assessments</h3>
              <p>
                Measure and improve your team's understanding of different
                cultures, fostering global collaboration and respect for
                diversity.
              </p>
            </div>
            <div className="feature-item">
              <h3>Anonymous Inclusivity Feedback</h3>
              <p>
                Enable employees to submit anonymous feedback regarding
                workplace inclusivity, helping to identify areas for
                improvement.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section">
          <h2>Benefits of Fostering Inclusivity and Communication</h2>
          <div className="benefits-list">
            <div className="benefit-card">
              <h3>Enhanced Team Collaboration</h3>
              <p>
                Inclusive communication strengthens team collaboration, leading
                to better understanding and teamwork among diverse groups.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Improved Employee Satisfaction</h3>
              <p>
                When employees feel heard and valued, overall job satisfaction
                increases, leading to a more positive work environment.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Reduced Workplace Conflicts</h3>
              <p>
                Promoting clear and bias-free communication helps reduce
                misunderstandings and conflicts in the workplace.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Strengthened Organizational Values</h3>
              <p>
                Fostering inclusivity helps build a workplace culture that
                aligns with your organization's values, attracting and retaining
                top talent.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h2>Ready to Build an Inclusive and Communicative Workplace?</h2>
          <p>
            Take the next step with CultureLinkr to create an environment where
            inclusivity thrives, and communication flourishes.
          </p>
          <button className="cta-button">
            <a href="take-a-tour">Take a Tour</a>
          </button>
        </section>
      </div>
    </Layout>
  );
};

export default InclusivityCommunication;
