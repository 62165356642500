import React, { useState } from "react";
import "../styles/EnterprisePricingPage.css";
import Modal from "../components/Modal"; // Assuming you have a Modal component
import Layout from "./Layout";

const EnterprisePricingPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [faqs] = useState([
    {
      question: "What is included in the Enterprise Premium Plan?",
      answer:
        "The Enterprise Premium Plan includes access to all wellness programs with customization options, advanced inclusivity metrics, comprehensive feedback tools, full customization of reports, and priority support.",
    },
    {
      question: "Can I customize my Enterprise Premium Plan?",
      answer:
        "Yes, you can fully customize your plan with additional integration, analytics, and white-labeling options tailored to your needs.",
    },
    {
      question: "Is there a limit to the number of users?",
      answer:
        "The Enterprise Premium Plan is designed for 200+ employees, with custom pricing for additional users beyond the 200 limit.",
    },
    {
      question: "What kind of support is available?",
      answer:
        "The Enterprise Premium Plan comes with a dedicated account manager, 24/7 priority support, and full access to customization and integration assistance.",
    },
    {
      question: "Can I switch from Premium to Enterprise Premium?",
      answer:
        "Yes, you can upgrade from Premium to Enterprise Premium anytime. Please contact support for further assistance.",
    },
  ]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <div className="enterprise-pricing-page">
        <h1 className="page-title">Enterprise Pricing Plans</h1>
        <h3 className="page-sub-title">Choose the plan that best suits your organization's needs.</h3>

        <div className="pricing-plans">
          {/* Standard Plan */}
          <div className="pricing-card standard">
            <h2>Standard Plan</h2>
            <p className="original-price">
              Monthly: <span className="discounted-price">$249</span>{" "}
              <span className="price-cut">$299</span>
            </p>
            <ul className="benefits-list">
              <li>Access to Core Wellness Programs</li>
              <li>Basic Inclusivity Metrics and Reporting</li>
              <li>Standard Feedback Collection and Tracking</li>
              <li>Standard Reports and Dashboards</li>
              <li>Basic Integrations (HR systems, communication tools)</li>
            </ul>
            <button className="cta-button">
              <a href="/schedule-demo">Choose Standard</a>
            </button>
          </div>

          {/* Premium Plan */}
          <div className="pricing-card premium">
            <h2>Premium Plan</h2>
            <p className="original-price">
              Monthly: <span className="discounted-price">$449</span>{" "}
              <span className="price-cut">$499</span>
            </p>
            <ul className="benefits-list">
              <li>Access to All Wellness Programs, including Advanced Options</li>
              <li>Detailed Inclusivity Metrics and Benchmarking</li>
              <li>Anonymous Feedback and Trend Analysis</li>
              <li>Advanced Reporting with Custom Dashboards</li>
              <li>Advanced Integrations (CRM, other business tools)</li>
            </ul>
            <button className="cta-button">
              <a href="/schedule-demo">Choose Premium</a>
            </button>
          </div>

          {/* Enterprise Premium Plan */}
          <div className="pricing-card enterprise">
            <h2>Enterprise Premium Plan</h2>
            <p className="original-price">
              Monthly: <span className="discounted-price">$849</span>{" "}
              <span className="price-cut">$999</span>
            </p>
            <ul className="benefits-list">
              <li>Access to All Wellness Programs with Customization Options</li>
              <li>Advanced Inclusivity Metrics with Real-time Updates</li>
              <li>Comprehensive Feedback Management with Actionable Insights</li>
              <li>Full Customization of Reports and Predictive Analytics</li>
              <li>API Access for Custom Integrations</li>
            </ul>
            <button className="cta-button">
              <a href="/schedule-demo">Choose Enterprise Premium</a>
            </button>
          </div>
        </div>

        <button className="comparison-button" onClick={handleModalOpen}>
          Compare Plans
        </button>

        {/* Modal for Price Comparison */}
        {isModalOpen && (
          <Modal onClose={handleModalClose}>
            <h2>Price Comparison</h2>
            <table>
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Standard Plan</th>
                  <th>Premium Plan</th>
                  <th>Enterprise Premium Plan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Access to Wellness Programs</td>
                  <td>Core wellness programs</td>
                  <td>All wellness programs, including advanced options</td>
                  <td>All wellness programs with customization options</td>
                </tr>
                <tr>
                  <td>Inclusivity and Diversity Metrics</td>
                  <td>Basic metrics and reporting</td>
                  <td>Detailed inclusivity metrics and benchmarking</td>
                  <td>Advanced metrics, real-time updates, tailored assessments</td>
                </tr>
                <tr>
                  <td>Feedback System</td>
                  <td>Standard feedback collection</td>
                  <td>Anonymous feedback and trend analysis</td>
                  <td>Comprehensive feedback management with insights</td>
                </tr>
                <tr>
                  <td>Reports and Dashboards</td>
                  <td>Standard reports</td>
                  <td>Custom dashboards with advanced analytics</td>
                  <td>Full customization with predictive analytics</td>
                </tr>
                <tr>
                  <td>Integration Capabilities</td>
                  <td>Basic integrations (HR systems, communication tools)</td>
                  <td>Advanced integrations (CRM, other business tools)</td>
                  <td>API access for custom integrations</td>
                </tr>
                <tr>
                  <td>User Management and Security</td>
                  <td>Basic profiles and role-based access</td>
                  <td>Advanced management and security</td>
                  <td>Enhanced security and compliance features</td>
                </tr>
                <tr>
                  <td>Support</td>
                  <td>Standard email support</td>
                  <td>Priority support with email and chat</td>
                  <td>Dedicated account manager and 24/7 support</td>
                </tr>
              </tbody>
            </table>
            <button className="close-modal" onClick={handleModalClose}>
              Close
            </button>
          </Modal>
        )}

        {/* FAQs Section */}
        <div className="faqs-section">
          <h2>Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <h3 className="faq-question">{faq.question}</h3>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default EnterprisePricingPage;
