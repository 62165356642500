import React, { useEffect, useState } from "react";
import "../styles/NavigationBar.css"; // Import the CSS for styling

const NavigationBar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [flippedIndex, setFlippedIndex] = useState(null); // State to manage which arrow is flipped

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuItems = [
    {
      title: "Why Us?",
      links: [
        { name: "Why choose Us?", href: "/why-us" },
        { name: "Interactive Product Tour?", href: "/take-a-tour" },
      ],
    },
    {
      title: "Products",
      links: [
        { name: "Employee Engagement", href: "/employee-engagement" },
        { name: "Predictive Analysis", href: "/predictive-analysis" },
        { name: "Inclusivity & Communication", href: "/inclusivity-communication" },
        { name: "Manager Insights", href: "/manager-insights" },
        { name: "Counselling & Support", href: "/counselling-support" },
      ],
    },
    {
      title: "Resources",
      links: [
        { name: "Content Library", href: "/content-library" },
        { name: "Help Center", href: "/help-center" },
        { name: "Events", href: "/events" },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About", href: "/about" },
        { name: "Contact Us", href: "/contact-us" },
      ],
    },
    {
      title: "Pricing",
      links: [
        { name: "Individual Pricing", href: "/individual-pricing" },
        { name: "Enterprise Pricing", href: "/enterprise-pricing" },
      ],
    },
  ];

  // Function to handle mouse enter and leave events
  const handleMouseEnter = (index) => setFlippedIndex(index);
  const handleMouseLeave = () => setFlippedIndex(null);

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img alt="culturelinkr-logo" src="/logo.png" />
          CultureLinkr
        </a>
        <ul className="navbar-menu">
          {menuItems.map(({ title, links }, index) => (
            <li
              className="navbar-item"
              key={title}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="navbar-title">
                {title}
                <span className={`arrow`}>
                  {flippedIndex === index ? '▲' : '▼'}
                </span>{" "}
                {/* Up Arrow if flipped, Down Arrow otherwise */}
              </div>
              <ul className="dropdown-menu">
                {links.map(({ name, href }) => (
                  <li key={name}>
                    <a href={href}>{name}</a>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <button className="navbar-cta-button"><a href="/schedule-demo">Get Started</a></button>
      </div>
    </nav>
  );
};

export default NavigationBar;
