import React, { useState } from "react";
import "../styles/ContentLibrary.css";
import Layout from "./Layout";

const ContentLibrary = () => {
  // State to store search input
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");

  // Sample content data
  const contentData = [
    {
      title: "Employee Engagement",
      category: "engagement",
      description:
        "Discover best practices for keeping your team motivated and engaged.",
    },
    {
      title: "Wellbeing Tools",
      category: "wellbeing",
      description:
        "Access resources that promote mental health and employee wellness.",
    },
    {
      title: "Predictive Analytics",
      category: "analytics",
      description:
        "Leverage data-driven insights to anticipate workforce trends and needs.",
    },
    {
      title: "Inclusivity Enhancements",
      category: "inclusivity",
      description:
        "Learn how to foster inclusivity and reduce bias in the workplace.",
    },
    {
      title: "Recognition & Rewards",
      category: "recognition",
      description:
        "Find strategies for recognizing and rewarding employees effectively.",
    },
  ];

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Handle category change
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Filter content based on search query and category
  const filteredContent = contentData.filter((item) => {
    const matchesCategory =
      selectedCategory === "all" || item.category === selectedCategory;
    const matchesSearch =
      item.title.toLowerCase().includes(searchQuery) ||
      item.description.toLowerCase().includes(searchQuery);

    return matchesCategory && matchesSearch;
  });

  return (
    <Layout>
      <div className="content-library-page">
        {/* Header Section */}
        <header className="header">
          <h1>Content Library</h1>
          <p>
            Explore resources to enhance your workplace culture, employee
            engagement, and inclusivity.
          </p>
        </header>
        <span style={{color: 'red', margin: '10px auto'}}>
          More Content Coming soon...
        </span>
        {/* Filter and Search Section */}
        <div className="filter-search-section">
          <input
            type="text"
            className="search-bar"
            placeholder="Search content..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <select
            className="filter-dropdown"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="all">All Categories</option>
            <option value="engagement">Employee Engagement</option>
            <option value="wellbeing">Wellbeing Tools</option>
            <option value="analytics">Predictive Analytics</option>
            <option value="inclusivity">Inclusivity Enhancements</option>
            <option value="recognition">Recognition & Rewards</option>
          </select>
        </div>

        {/* Content Categories */}
        <div className="content-grid">
          {filteredContent.length > 0 ? (
            filteredContent.map((item, index) => (
              <div className="content-card" key={index}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            ))
          ) : (
            <p>No content found matching your search criteria.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ContentLibrary;
