import React from "react";
import "../styles/ManagerInsights.css"; // Create a separate CSS file for styling
import Layout from "./Layout";

const ManagerInsights = () => {
  return (
    <Layout>
      <div className="manager-insights">
        {/* Header Section */}
        <header className="header-section">
          <h1>Manager Insights</h1>
          <p>
            Empowering managers with real-time data and insights to lead
            inclusive, engaged, and high-performing teams.
          </p>
        </header>

        {/* Overview Section */}
        <section className="overview-section">
          <h2>What is Manager Insights?</h2>
          <p>
            Manager Insights provides leaders with actionable data and reports
            to better understand employee engagement, inclusivity, and overall
            team well-being. This tool helps managers make informed decisions to
            improve team morale, address disengagement, and foster a thriving
            work environment.
          </p>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>Key Features of Our Manager Insights Tools</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>Team Climate Reports</h3>
              <p>
                Get comprehensive reports on team morale, engagement levels, and
                inclusivity, helping you understand the health of your team.
              </p>
            </div>
            <div className="feature-item">
              <h3>Engagement and Inclusivity Heatmaps</h3>
              <p>
                Visualize your team's engagement and inclusivity trends with
                easy-to-read heatmaps, enabling quick identification of areas
                for improvement.
              </p>
            </div>
            <div className="feature-item">
              <h3>Disengagement Risk Alerts</h3>
              <p>
                Receive alerts when employees show signs of disengagement,
                allowing proactive intervention to maintain team productivity
                and satisfaction.
              </p>
            </div>
            <div className="feature-item">
              <h3>Personalized Action Plans</h3>
              <p>
                Access tailored action plans and suggestions to enhance team
                engagement, improve communication, and promote inclusivity.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section">
          <h2>Benefits of Using Manager Insights</h2>
          <div className="benefits-list">
            <div className="benefit-card">
              <h3>Improved Decision-Making</h3>
              <p>
                Leverage data-driven insights to make informed decisions that
                positively impact team engagement and performance.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Enhanced Employee Retention</h3>
              <p>
                Identify potential issues early and address disengagement to
                retain top talent and foster a positive work environment.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Increased Productivity</h3>
              <p>
                Managers can take proactive steps to maintain high levels of
                engagement, leading to increased team productivity and
                collaboration.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Stronger Team Dynamics</h3>
              <p>
                Build stronger relationships within teams by addressing
                concerns, promoting inclusivity, and fostering open
                communication.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h2>Ready to Gain Insight into Your Team?</h2>
          <p>
            Discover how CultureLinkr's Manager Insights can transform your
            leadership approach by providing real-time data on employee
            engagement and inclusivity.
          </p>
          <button className="cta-button">
            <a href="take-a-tour">Take a Tour</a>
          </button>
        </section>
      </div>
    </Layout>
  );
};

export default ManagerInsights;
