import React from "react";
import "../styles/WhyCultureLinkr.css";

const WhyCultureLinkr = () => {
  return (
    <section className="why-culturelinkr">
      <div className="container">
        <h2>Why CultureLinkr?</h2>

        <div className="card-container">
          <div className="card">
            <h3>Empower Managers with Data-Driven Insights</h3>
            <p>
              At CultureLinkr, we believe that thriving teams start with informed
              managers. Our platform offers <strong>real-time insights</strong> and 
              <strong>actionable reports</strong> that empower managers to stay connected
              with their teams, detect early signs of disengagement, and make smarter 
              decisions. With predictive analytics and behavioral insights, you'll always
              know how to keep your team motivated and aligned.
            </p>
          </div>

          <div className="card">
            <h3>Foster Inclusivity and Boost Engagement</h3>
            <p>
              We’re passionate about creating workplaces where 
              <strong> everyone feels valued</strong>. CultureLinkr provides tools that 
              promote inclusivity and prevent bias in communication. From personalized 
              inclusivity training to anonymous feedback channels, our platform ensures 
              that every voice is heard and every individual thrives. Build a culture 
              that not only drives engagement but also innovation.
            </p>
          </div>

          <div className="card">
            <h3>Identify Silent Resignation Early</h3>
            <p>
              Silent resignation can hurt your organization before you even notice. 
              Our AI-driven models analyze team behaviors and communication patterns 
              to identify potential risks of disengagement. By acting early, managers 
              can take the right steps to re-engage employees and improve retention.
            </p>
          </div>

          <div className="card">
            <h3>Prioritize Employee Wellbeing</h3>
            <p>
              CultureLinkr places <strong>employee wellbeing at the center</strong> of 
              its mission. With features like <strong>mental health check-ins</strong>, 
              personalized wellness programs, and virtual team activities, we help you 
              nurture happier, healthier, and more productive teams. Prioritize mental 
              and emotional health with the tools that matter.
            </p>
          </div>

          <div className="card">
            <h3>Customizable Solutions for Every Organization</h3>
            <p>
              From startups to large enterprises, CultureLinkr adapts to your needs. 
              Choose from our <strong>Standard, Premium, and Enterprise</strong> plans 
              to access the features that best align with your organizational goals. 
              Whether you’re a small team or a large workforce, we’ve got the right 
              tools for you.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyCultureLinkr;
