import React from "react";
import "../styles/AboutPage.css";
import Layout from "./Layout";

const AboutPage = () => {
  return (
    <Layout>
      <div className="about-page">
        {/* About Header Section */}
        <header className="about-header">
          <h1>About CultureLinkr</h1>
          <p>
            We’re dedicated to building a world where employees and
            organizations grow together. CultureLinkr helps companies foster
            inclusivity, wellbeing, and engagement for all.
          </p>
          <div className="header-img">
            <img
              src="/images/inclusive-workspace.svg"
              alt="Inclusive workplace"
            />
          </div>
        </header>

        {/* Mission Statement Section */}
        <section className="mission-statement">
          <h2>Our Mission</h2>
          <p>
            At CultureLinkr, our mission is to bridge the gap between employees
            and organizations by fostering a culture of inclusivity, engagement,
            and mutual growth. We believe that happy, engaged employees are key
            to long-term success for any organization.
          </p>
        </section>

        {/* Vision and Values Section */}
        <section className="vision-values">
          <h2>Our Vision & Values</h2>
          <div className="values-cards">
            <div className="value-card">
              <h3>Inclusivity</h3>
              <p>
                We champion diversity and create tools that help organizations
                promote fairness and representation across all levels.
              </p>
            </div>
            <div className="value-card">
              <h3>Engagement</h3>
              <p>
                By improving employee wellbeing, we aim to build cultures where
                people feel empowered and connected.
              </p>
            </div>
            <div className="value-card">
              <h3>Innovation</h3>
              <p>
                We believe in using technology to create solutions that drive
                real change and help organizations stay ahead.
              </p>
            </div>
          </div>
        </section>

        {/* Interactive Timeline Section */}
        <section className="timeline-section">
          <h2>Our Journey</h2>
          <div className="timeline">
            <div className="timeline-item">
              <h4>2023</h4>
              <p>
              CultureLinkr was founded to address personal loneliness for employees and
              improve workplace inclusivity.
              </p>
            </div>
            <div className="timeline-item">
              <h4>2024</h4>
              <p>
                Starting October 2024, we’ve dedicated ourselves to working hard
                on CultureLinkr’s development and growth. We are expanding for company level workplace improvement.
              </p>
            </div>
          </div>
        </section>

        {/* Team Section - Card Format */}
        <section className="team-section">
          <h2>Meet Our Team</h2>
          <div className="team-cards">
            <div className="team-card">
              <img
                src="/images/Pragati-kumar.jpeg"
                alt="Pragati Kumar"
                className="team-image"
              />
              <h3>Pragati Kumar</h3>
              <p>Founder & CEO</p>
              <p className="team-bio">
                Pragati is passionate about creating inclusive workplaces. With
                years of experience in tech and leadership, she drives the
                vision of CultureLinkr.
              </p>
            </div>
            {/* Add more team cards as needed */}
          </div>
        </section>

        {/* How We Work Section */}
        <section className="how-we-work">
          <h2>How We Work</h2>
          <p>
            We blend technology with human insight to create solutions that are
            intuitive, functional, and transformative. Our team is committed to
            innovation, continually enhancing the experience for both employees
            and employers.
          </p>
          <div className="work-img">
            <img src="/images/work-process.svg" alt="Our Work Process" />
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h2>Join Us on Our Journey</h2>
          <p>
            Ready to take your organization’s culture to the next level?
            Discover how CultureLinkr can help you foster engagement and
            inclusivity.
          </p>
          <button className="cta-button">
            <a href="take-a-tour">Get Started</a>
          </button>
        </section>
      </div>
    </Layout>
  );
};

export default AboutPage;
