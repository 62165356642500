import React from "react";
import "../styles/EmployeeEngagement.css"; // Create a separate CSS file for styling
import Layout from "./Layout";

const EmployeeEngagement = () => {
  return (
    <Layout>
      <div className="employee-engagement">
        {/* Header Section */}
        <header className="header-section">
          <h1>Employee Engagement</h1>
          <p>
            Empowering employees to thrive through meaningful connections and a
            vibrant workplace culture.
          </p>
        </header>

        {/* Overview Section */}
        <section className="overview-section">
          <h2>What is Employee Engagement?</h2>
          <p>
            Employee engagement is the emotional commitment employees have to
            their organization. When employees are engaged, they feel valued and
            are more likely to contribute to the success of the organization. At
            CultureLinkr, we provide tools to foster an engaging workplace where
            employees feel connected and motivated.
          </p>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>Key Features of Our Employee Engagement Tools</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>Real-Time Feedback</h3>
              <p>
                Foster open communication through real-time feedback mechanisms
                that allow employees to express their thoughts and concerns.
              </p>
            </div>
            <div className="feature-item">
              <h3>Wellness Programs</h3>
              <p>
                Implement personalized wellness programs that prioritize mental
                and physical health, enhancing overall employee satisfaction.
              </p>
            </div>
            <div className="feature-item">
              <h3>Team Building Activities</h3>
              <p>
                Organize engaging team-building activities that strengthen
                relationships among employees and promote collaboration.
              </p>
            </div>
            <div className="feature-item">
              <h3>Recognition and Rewards</h3>
              <p>
                Create a culture of recognition where achievements are
                celebrated, fostering a sense of belonging and motivation among
                employees.
              </p>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section">
          <h2>Benefits of Enhanced Employee Engagement</h2>
          <div className="benefits-list">
            <div className="benefit-card">
              <h3>Improved Employee Morale</h3>
              <p>
                Engaged employees are more positive and enthusiastic about their
                work, leading to a happier workplace.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Increased Productivity</h3>
              <p>
                Motivated employees are more productive and committed to
                achieving organizational goals.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Reduced Turnover Rates</h3>
              <p>
                Higher engagement levels lead to lower turnover rates, saving
                costs on hiring and training new employees.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Stronger Organizational Culture</h3>
              <p>
                Engaged employees contribute to a positive workplace culture
                that attracts and retains talent.
              </p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h2>Ready to Enhance Employee Engagement?</h2>
          <p>
            Join us at CultureLinkr to create an engaging and inclusive
            workplace where employees can thrive and contribute to
            organizational success.
          </p>
          <button className="cta-button">
            <a href="take-a-tour">Take a Tour</a>
          </button>
        </section>
      </div>
    </Layout>
  );
};

export default EmployeeEngagement;
