import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-grid">
          <div className="footer-section">
            <h4>Company</h4>
            <ul>
              <li>
                <a href="/about">About Us</a>
              </li>
              <li>
                <a href="/individual-pricing">Individual Pricing</a>
              </li>
              <li>
                <a href="/enterprise-pricing">Enterprise Pricing</a>
              </li>
              <li>
                <a href="/events">Events</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h4>Solutions</h4>
            <ul>
              <li>
                <a href="/employee-engagement">Employee Engagement</a>
              </li>
              <li>
                <a href="/predictive-analysis">Predictive Analysis</a>
              </li>
              <li>
                <a href="/inclusivity-communication">Inclusivity & Communication</a>
              </li>
              <li>
                <a href="/manager-insights">Manager Insights</a>
              </li>
              <li>
                <a href="/counselling-support">Counselling & Support</a>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h4>Resources</h4>
            <ul>
              <li>
                <a href="/content-library">Content Library</a>
              </li>
              <li>
                <a href="/help-center">Help Center</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-of-service">Terms of Service</a>
              </li>
            </ul>
          </div>

          <div className="footer-section">
            <h4>Get in Touch</h4>
            <ul>
              <li>
                <a href="mailto:support@culturelinkr.com">
                  support@culturelinkr.com
                </a>
              </li>
              <li>
                <a href="tel:+918839307937">+91 88393-07937</a>
              </li>
              <li>
                <div className="social-media">
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/culturelinkr/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </li>
              <li>
                <a
                  href="https://wa.me/+918839307937"
                  className="whatsapp-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-whatsapp"></i> WhatsApp Us
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="footer-love">
          Made with <span className="heart">♥</span> by CultureLinkr
        </p>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} CultureLinkr. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
