import React from 'react';
import '../styles/HeroSection.css'; // Create this CSS file for styling

const HeroSection = () => {
  return (
    <div className="hero-container">
      <div className="hero-content1">
        <h1>Transform Your Workplace Culture with CultureLinkr</h1>
        <p>Enhance employee engagement, inclusivity, and productivity with our innovative platform.</p>
        <div className='action-container'>
        <button className="request-demo-btn"><a href="/schedule-demo">Schedule Demo</a></button>
        <button className="take-a-tour-btn"><a href="/take-a-tour">Take a Tour</a></button>
        </div>
      </div>
      <div className="hero-content2">
        <img src="images/hero-image.svg" height="90%" width="100%" alt="engagement logo"></img>
      </div>
    </div>
  );
};

export default HeroSection;
