import React from "react";
import "../styles/WhyChooseUs.css"; // Create a separate CSS file for styling
import Layout from "./Layout";

const WhyChooseUs = () => {
  return (
    <Layout>
      <div className="why-choose-us">
        {/* Header Section */}
        <header className="header-section">
          <h1>Why Choose CultureLinkr?</h1>
          <p>
            Discover how CultureLinkr can transform your workplace into a hub of inclusivity, engagement, and employee well-being.
          </p>
        </header>

        {/* Key Benefits Section */}
        <section className="benefits-section">
          <h2>Key Benefits of CultureLinkr</h2>
          <div className="benefits-cards">
            <div className="benefit-card">
              <h3>Inclusivity</h3>
              <p>
                Our platform is designed to promote inclusivity at every level of your organization, ensuring every voice is heard and valued.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Engagement</h3>
              <p>
                Increase employee engagement through targeted wellness programs and interactive tools that foster connection and collaboration.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Data-Driven Insights</h3>
              <p>
                Leverage powerful analytics to understand employee sentiment and make informed decisions that drive positive change in your organization.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Tailored Solutions</h3>
              <p>
                Our customizable features cater to the unique needs of your organization, providing tailored solutions for maximum impact.
              </p>
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section">
          <h2>What Our Clients Say</h2>
          <div className="testimonials">
            <blockquote>
              <p>
              "As an employee at Piramal Healthcare, CultureLinkr has been instrumental in reducing stress and improving personal well-being. The platform's wellness programs and mental health resources have helped me stay focused and maintain a healthier work-life balance."
              </p>
              <cite>— Ajit Kumar (Piramal Healthcare)</cite>
            </blockquote>
            <blockquote>
              <p>
              "CultureLinkr has greatly improved my mental health and personal wellbeing. Its tools promote balance and mindfulness, reducing stress and boosting focus, while fostering a more inclusive and positive work environment at VP Agritech."
              </p>
              <cite>— Nandkishore (VP Agritech)</cite>
            </blockquote>
            <blockquote>
              <p>
              "As a legal trainee at Velnik, CultureLinkr has significantly improved my mental well-being. The Wellbeing Tools and virtual team activities help me manage stress, while the real-time feedback keeps me engaged. The platform fosters a positive, inclusive environment, making work more fulfilling and balanced."
              </p>
              <cite>— Tejaswini Kumar (Velnik)</cite>
            </blockquote>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="cta-section">
          <h2>Ready to Transform Your Workplace?</h2>
          <p>
            Join the growing number of organizations that are making inclusivity a priority. Let’s work together to create a better workplace for everyone.
          </p>
          <a href="/schedule-demo"><button className="cta-button">Get Started Today</button></a>
        </section>
      </div>
    </Layout>
  );
};

export default WhyChooseUs;
