import React from "react";
import "../styles/PredictiveAnalysis.css";
import Layout from "./Layout";

const PredictiveAnalysisPage = () => {
  return (
    <Layout>
      <div className="predictive-analysis-page">
        {/* Header Section */}
        <header className="predictive-header">
          <h1>Predictive Analysis</h1>
          <p>
            Leverage data-driven insights to enhance your organizational culture
            and employee engagement.
          </p>
        </header>

        {/* What is Predictive Analysis Section */}
        <section className="predictive-analysis">
          <h2>What is Predictive Analysis?</h2>
          <p>
            Predictive analysis is a data-driven approach that utilizes
            statistical algorithms and machine learning techniques to identify
            the likelihood of future outcomes based on historical data. It helps
            organizations make informed decisions by forecasting trends,
            behaviors, and potential risks.
          </p>
          <div className="predictive-image">
            <img
              src="/images/predictice-analysis.svg"
              alt="Predictive Analysis"
            />
          </div>
        </section>

        {/* Benefits of Predictive Analysis Section */}
        <section className="benefits-section">
          <h2>Benefits of Predictive Analysis</h2>
          <p>
            Leverage the power of predictive analysis to enhance your workplace
            environment. Here are the key benefits:
          </p>
          <div className="benefits-cards">
            <div className="benefit-card">
              <h3>Informed Decision-Making</h3>
              <p>
                Utilize data-driven insights to make proactive decisions that
                align with employee needs and organizational goals.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Enhanced Employee Retention</h3>
              <p>
                Identify at-risk employees early and implement strategies to
                improve retention and engagement.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Optimized Performance</h3>
              <p>
                Recognize patterns in employee performance to foster a
                high-performing culture and improve productivity.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Better Resource Allocation</h3>
              <p>
                Allocate resources efficiently based on predictive insights,
                ensuring teams have what they need to succeed.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Proactive Engagement Strategies</h3>
              <p>
                Design targeted engagement initiatives based on predictive data,
                enhancing the overall employee experience.
              </p>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how-it-works">
          <h2>How Predictive Analysis Works</h2>
          <p>
            Our Predictive Analysis feature employs advanced algorithms and
            machine learning techniques to interpret and analyze employee data.
          </p>
          <div className="flowchart">
            <div className="flowchart-node">
              <h3>Data Collection</h3>
              <p>Gather data from surveys, reviews, and metrics.</p>
            </div>
            <div className="flowchart-arrow">↓</div>

            <div className="flowchart-node">
              <h3>Data Analysis</h3>
              <p>Analyze data to identify patterns in engagement.</p>
            </div>
            <div className="flowchart-arrow">↓</div>

            <div className="flowchart-node">
              <h3>Predictive Modeling</h3>
              <p>Create models forecasting retention and engagement.</p>
            </div>
            <div className="flowchart-arrow">↓</div>

            <div className="flowchart-node">
              <h3>Actionable Insights</h3>
              <p>Provide recommendations for enhancing workplace culture.</p>
            </div>
            <div className="flowchart-arrow">↓</div>

            <div className="flowchart-node">
              <h3>Continuous Monitoring</h3>
              <p>Monitor trends and adjust models for accurate insights.</p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="call-to-action">
          <h2>Ready to Elevate Your Employee Engagement?</h2>
          <p>
            Discover how Predictive Analysis can transform your organization and
            enhance workplace culture.
          </p>
          <button className="cta-button">
            <a href="take-a-tour">Take a Tour</a>
          </button>
        </section>
      </div>
    </Layout>
  );
};

export default PredictiveAnalysisPage;
