import React from "react";
import "../styles/FeaturesSection.css"; // Create this CSS file for styling

const features = [
  {
    id: 1,
    title: "Engagement Programs",
    description: "Create and manage programs to boost employee engagement.",
    imgPath: "/images/engagement-programs.svg",
  },
  {
    id: 2,
    title: "Inclusivity Insights",
    description:
      "Analyze and enhance workplace inclusivity with our advanced tools.",
    imgPath: "/images/inclusivity-insights.svg",
  },
  {
    id: 3,
    title: "Real-time Analytics",
    description:
      "Track and measure the impact of your programs with real-time data.",
    imgPath: "/images/real-time-analytics.svg",
  },
  {
    id: 4,
    title: "Manager Insights",
    description:
      "Gain actionable insights and reports to effectively manage and support your team.",
    imgPath: "/images/manager-insights.svg",
  },
];

const FeaturesSection = () => {
  return (
    <div className="features-container">
      <h2>Platform</h2>
      <h1>Unleash potential with CultureLinkr’s innovative platform!</h1>
      <div className="features-grid">
        {features.map((feature) => (
          <div key={feature.id} className="feature-card">
            <img src={feature.imgPath} alt={feature.title} />
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturesSection;
