import React, { useState } from "react";
import "../styles/HelpCenter.css"; // Create and link a separate CSS file for styling
import Layout from "./Layout";

const faqs = [
  {
    question: "What is CultureLinkr?",
    answer:
      "CultureLinkr is an all-in-one B2B platform that promotes employee engagement, inclusivity, and predictive analytics to improve corporate culture and wellbeing.",
  },
  {
    question: "How does CultureLinkr detect silent resignation?",
    answer:
      "CultureLinkr uses behavioral analytics and sentiment analysis of communication patterns to detect early signs of disengagement and silent resignation.",
  },
  {
    question: "Can CultureLinkr integrate with existing tools?",
    answer:
      "Yes! CultureLinkr integrates with various HR, communication, and project management tools to provide seamless workflow and data integration.",
  },
  {
    question: "What employee engagement features does CultureLinkr offer?",
    answer:
      "CultureLinkr offers virtual social spaces, personalized wellness programs, peer recognition, and virtual team activities to enhance employee engagement.",
  },
  {
    question: "Is CultureLinkr customizable for large organizations?",
    answer:
      "Yes, our Enterprise Premium Plan offers customizable features that can be tailored to large organizations with specific needs.",
  },
  {
    question: "How does CultureLinkr ensure data privacy?",
    answer:
      "CultureLinkr adheres to strict data privacy and security standards, including anonymized data analytics and compliance with mental health and inclusivity standards.",
  },
  {
    question: "What is included in the Premium Plan?",
    answer:
      "The Premium Plan includes advanced employee engagement features, predictive analytics, inclusivity tools, and manager insights for mid-sized businesses.",
  },
  {
    question: "How can I request a demo for CultureLinkr?",
    answer:
      "You can request a demo by visiting our 'Schedule a Demo' page or contacting us directly via email or phone for personalized assistance.",
  },
  {
    question: "Can I try CultureLinkr for free?",
    answer:
      "Yes, we offer a free trial for our Standard Plan to help you explore basic features before committing to a paid subscription.",
  },
];

const HelpCenterPage = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Layout>
      <div className="help-center-container">
        <h1 className="help-center-title">Help Center</h1>
        <p className="help-center-description">
          Find answers to the most commonly asked questions about CultureLinkr.
        </p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${
                expandedIndex === index ? "expanded" : ""
              }`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="faq-question">
                {faq.question}
                <span className="faq-toggle-icon">
                  {expandedIndex === index ? "-" : "+"}
                </span>
              </div>
              {expandedIndex === index && (
                <div className="faq-answer">{faq.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default HelpCenterPage;
